import { HttpErrorResponse } from "@angular/common/http";

export class Http401Error extends Error {
}

export abstract class NpErrorUtils {

    static alert(response: HttpErrorResponse | Http401Error | Error | any) {

        let errorMessage = '';

        if (response instanceof HttpErrorResponse) {
            if (response.error?.message) {
                errorMessage = response.error.message;
            } else {
                errorMessage = response.status + ' ' + response.statusText;
            }

        } else {
            errorMessage = response.message;
        }

        if (response instanceof Http401Error) {
            return;
        }

        window.alert(errorMessage);

    }

    static alertAndThrow(response: HttpErrorResponse | Http401Error | Error) {
        NpErrorUtils.alert(response);
        throw response;
    }

}